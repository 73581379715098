/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "material-icons/iconfont/material-icons.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";

@font-face {
  font-family: "Calibri-Custom";
  src: url("./assets/fonts/Calibri.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Calibri-Custom";
  src: url("./assets/fonts/Calibri-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Calibri-Custom";
  src: url("./assets/fonts/Calibri-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Calibri-Custom";
  src: url("./assets/fonts/Calibri-Bold-Italic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  --ion-color-primary: #3880ff; /* Adjust this to your primary color */
  --ion-color-secondary: #3dc2ff; /* Adjust this to your secondary color */
  --ion-color-tertiary: #7044ff; /* Adjust this to your tertiary color */
  --ion-background-color: #ffffff; /* Background color */
  --ion-text-color: #000000; /* Text color */
  --ion-toolbar-background: #ffffff; /* Toolbar background */
  --ion-toolbar-color: #000000; /* Toolbar text color */
  --ion-item-background: #ffffff; /* Item background */
  --ion-item-color: #000000; /* Item text color */
}

.max-width-xl {
  max-width: 1200px;
  margin: auto;
}

:root {
  --ion-color-date: #0d62f6;
  --ion-color-date-rgb: 50, 50, 255;
  --ion-color-date-contrast: #ffffff;
  --ion-color-date-contrast-rgb: 255, 255, 255;
  --ion-color-date-shade: #3880ff;
  --ion-color-date-tint: #6ca2ff;
}

.ion-color-date {
  --ion-color-base: var(--ion-color-date);
  --ion-color-base-rgb: var(--ion-color-date-rgb);
  --ion-color-contrast: var(--ion-color-date-contrast);
  --ion-color-contrast-rgb: var(--ion-color-date-contrast-rgb);
  --ion-color-shade: var(--ion-color-date-shade);
  --ion-color-tint: var(--ion-color-date-tint);
}

ion-datetime {
  height: 100%;
  width: 100%;
  max-width: 100%;

  &::part(wheel-item) {
    color: var(--ion-color-dark);
    font-size: 20px;
  }

  &::part(active) {
    color: var(--ion-color-date);
    font-weight: 600;
  }

  &::part(calendar-day):focus {
    background: var(--ion-color-date);
    color: #fff;
  }

  &::part(calendar-day selected) {
    background: var(--ion-color-date);
    color: #fff;
  }
}

ion-input,
ion-select {
  --highlight-color-valid: green;
}

ion-skeleton-text {
  height: 44px;
}

// ALERT
.custom-alert .alert-wrapper {
  border-radius: 12px;
  background: linear-gradient(145deg, #ffffff, #e6e6e6);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.custom-alert .alert-header {
  color: #007acc; // Customize header color
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
}

.custom-alert .alert-message {
  font-size: 1.1em;
  color: #333;
  text-align: justify;
  margin-top: 2px;
}

.alert-button-ok {
  color: #ffffff;
  background-color: #007acc;
  border-radius: 8px;
  font-weight: bold;
}

.fullscreen-modal {
  --width: 100%;
  --height: 100%;
}

/////////////////////////////
/// NG-SELECT FORM
.invalid-field {
  border-color: #eb445a !important;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
}

.invalid-label {
  color: #eb445a !important;
}

// PRINT-OUT
.divRight {
  float: right;
  text-align: left;
  width: 50%;
}
.divLeft {
  float: left;
  text-align: left;
  width: 50%;
}

// ion-content {
//   // --background: var(--ion-item-background, var(--ion-background-color, #fff));
//   --background: url("./assets/icon/Background.jpg") no-repeat fixed center /
//     cover;
//   // Workaround for issue related to css varialbes
//   // being recalculated with DOM changes
//   // https://github.com/ionic-team/ionic-framework/issues/17494
//   // https://github.com/ionic-team/ionic-framework/issues/16678
//   &::part(background) {
//     background: url("./assets/icon/Background.jpg") no-repeat fixed center /
//       cover;
//   }
// }

.generatePageContainerILL {
  .pageFormulir {
    width: 210mm;
    height: 297mm;
    padding-bottom: 0.4cm;
    padding-top: 0.3cm;
    padding-left: 1.43cm;
    padding-right: 1.31cm;
    background: white;
    margin-bottom: 0.5cm;
    font-size: 10pt;
    font-family: "Calibri-Custom";
    line-height: 16px; /* forces consistency across platforms */
    font-style: normal;
    box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.2);
    position: relative;
    box-sizing: border-box;

    @media print {
      margin: 0;
      box-shadow: none;
    }
  }

  .cover {
    line-height: 40px;
  }

  .printingContent {
    padding-top: 0;
    padding-bottom: 0;
    height: 960px;
    overflow: hidden;
  }

  .printingBorder {
    font-style: normal;
    p {
      margin-bottom: 2px;
    }
  }

  .printingHeader2 {
    height: 80px;
  }

  .navbar {
    position: sticky;
    top: 0;
    width: 100%;
    // background: #333;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    z-index: 100;
  }

  .navbarButton {
    background: #4caf50;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  #masterPage {
    width: 800px;
    margin: 0 auto;
    padding-top: 2.5%;
  }
  .page {
    width: 794px;
    height: 1126px;
    // padding-bottom: 0.4cm;
    // padding-top: 0.3cm;
    // padding-left: 1cm;
    // padding-right: 1cm;
    // background: rgba(255, 255, 255, 1);
    // font-size: 9pt;
    // font-style: normal;
    // line-height: normal;
  }

  // .titleWeight {
  //   font-size: 12pt;
  // }

  .boldHr {
    border: none;
    height: 1pt;
    color: #000; /* old IE */
    background-color: #000; /* Modern Browsers */
  }
  .divLeft {
    float: left;
    width: 50%;
  }
  .divLeft2 {
    float: left;
    width: 44%;
  }

  .divRight2 {
    float: right;
    text-align: left;
    width: 44%;
  }
  .divMid2 {
    width: 12%;
  }

  .printingFooter {
    height: 90px;
    font-size: 10pt;
  }

  td.paddingLeft {
    padding-left: 2pt;
    vertical-align: baseline;
  }

  .bordered-table {
    border-collapse: collapse;
    font-size: 9pt;
    line-height: 12px;

    td {
      border: 1px solid black;
      padding: 2px;
    }

    th {
      border: 1px solid black;
      padding: 3px;
    }
  }

  .bordered-table-pad-1 {
    border-collapse: collapse;
    font-size: 9pt;
    line-height: 12px;

    td {
      border: 1px solid black;
      padding: 1px;
    }

    th {
      border: 1px solid black;
      padding: 2px;
    }
  }

  .bordered-table-pad-0 {
    border-collapse: collapse;
    font-size: 9pt;
    line-height: 12px;

    td {
      border: 1px solid black;
    }

    th {
      border: 1px solid black;
    }
  }

  .bordered-table-mbx {
    border-collapse: collapse;
    font-size: 8pt;
    line-height: 11px;

    td {
      border: 1px solid black;
      padding: 2px;
    }

    th {
      border: 1px solid black;
      padding: 3px;
    }
  }

  .page-header {
    padding: 20px;
    text-align: center;
  }

  .page-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px 20px;

    .footer-content {
      display: flex;
      justify-content: space-between;
      font-size: 0.8em;

      .footer-left,
      .footer-right {
        p {
          margin: 0;
        }
      }
    }
  }

  .tblSimulasi {
    font-size: 11pt;
  }

  .replayData {
    font-size: 11pt;
    line-height: 18px; /* forces consistency across platforms */
  }
}

.generatePageContainerFPK {
  .pageFormulir {
    width: 210mm;
    height: 297mm;
    padding-bottom: 0.4cm;
    padding-top: 0.3cm;
    padding-left: 1cm;
    padding-right: 1cm;
    background: white;
    margin-bottom: 0.5cm;
    font-size: 8pt;
    font-family: "Calibri-Custom";
    font-style: normal;
    box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.2);
    position: relative;
    box-sizing: border-box;

    @media print {
      margin: 0;
      box-shadow: none;
    }
  }

  .printingContent {
    padding-top: 0;
    padding-bottom: 0;
    height: 260mm;
    overflow: hidden;
  }

  .printingBorder {
    font-size: 8pt;
    font-style: normal;
    line-height: 14px;
    p {
      margin-bottom: 2px;
    }
    background-color: white !important;
    color: black !important;
  }

  .printingHeader2 {
    height: 80px;
  }

  .navbar {
    position: sticky;
    top: 0;
    width: 100%;
    // background: #333;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    z-index: 100;
  }

  .navbarButton {
    background: #4caf50;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background: #45a049;
    }
  }

  #masterPage {
    width: 800px;
    margin: 0 auto;
    padding-top: 2.5%;
    background-color: white !important;
    color: black !important;
    visibility: visible !important;
  }

  .titleWeight {
    font-size: 12pt;
  }

  .noRightBorder {
    border-right: none !important;
  }

  .noLeftBorder {
    border-left: none !important;
  }

  .questionTable {
    td {
      border-collapse: collapse;
      border: 1px solid black;
      line-height: 16px;
    }
  }

  .printingFooter {
    margin-top: 10px;
  }
}

.generatePageContainerFPP {
  .pageFormulir {
    width: 210mm;
    height: 297mm;
    padding: 0.3cm 1cm 0.4cm;
    background: white;
    margin-bottom: 0.5cm;
    font-size: 8pt;
    font-family: "Calibri-Custom";
    font-style: normal;
    box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.2);
    position: relative;
    box-sizing: border-box;

    @media print {
      margin: 0;
      box-shadow: none;
    }
  }

  .printingBorder {
    font-style: normal;
    line-height: 13px;
  }
  .printingHeader2 {
    height: 80px;
  }

  .navbar {
    position: sticky;
    top: 0;
    width: 100%;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    z-index: 100;
  }

  .navbarButton {
    background: #4caf50;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background: #45a049;
    }
  }

  #masterPage {
    width: 800px;
    margin: 0 auto;
    padding-top: 2.5%;
  }

  .page {
    width: 794px;
    height: 1126px;
  }

  .divLeft {
    float: left;
    width: 50%;
  }

  .divLeft2 {
    float: left;
    width: 44%;
  }

  .divRight2 {
    float: right;
    text-align: left;
    width: 44%;
  }

  .divMid2 {
    width: 12%;
  }

  .printingFooter {
    height: 90px;
    font-size: 8pt;
  }

  td.paddingLeft {
    padding-left: 2pt;
    vertical-align: baseline;
  }

  .bordered-table {
    border-collapse: collapse;
    font-size: 8pt;

    td {
      border: 1px solid black;
      padding: 2px;
    }

    th {
      border: 1px solid black;
      padding: 3px;
    }
  }

  .titleWeight {
    font-size: 12pt;
  }

  .printingContent {
    padding-top: 0;
    padding-bottom: 0;
    height: 960px;
    overflow: hidden;
  }

  p {
    margin: 0;
  }
}
